<template>
    <div class="skills">
        <mdc-card-text>
            <mdc-title>
                Skills
                <span v-if="editable">
                    <mdc-button title="Edit" v-if="!editingSkills" @click="editProfileSkills"><mdc-icon icon="create"/></mdc-button>
                    <mdc-button title="Save" v-else @click="updateProfileSkills"><mdc-icon icon="done"/></mdc-button>
                </span>
            </mdc-title>
            <section>
            <div v-if="editingSkills">
                <draggable v-model="skills" class="mdc-chip-set" :options="{draggable:'.item', handle:'.handle'}">
                <div v-for="(skill, index) in skills" :key="index" class="item mdc-chip">
                    <div class="handle">
                    <span class="handleicon">☰</span>
                    <span class="mdc-chip__text">{{skill.name}}</span>
                    <span v-if="skill.rating" class="rating">{{skill.rating}}</span>
                    </div>
                    <i tabindex="0" role="button" @click="removeSkill(index)" class="draggable-cancel-icon mdc-chip__icon mdc-chip__icon--trailing material-icons">cancel</i>
                </div>
                </draggable>
                <div class="flex-row">
                <div class="flex-left">
                  <auto-complete-outline
                    label="New Skill"
                    :value="newSkillOption"
                    autocompleteId="'newSkill'"
                    :field="'name'"
                    @set="setNewSkill"
                    :items="skillList"
                    :min="1"
                  />
                </div>
                <div>
                    <mdc-select dense style="width:74px;height:54px;" outlined v-model="newSkillRating">
                      <select-item value="" />
                      <select-item value="1★" />
                      <select-item value="2★" />
                      <select-item value="3★" />
                      <select-item value="4★" />
                      <select-item value="5★" />
                    </mdc-select>
                </div>
                <div class="v-center">
                  <round-button @click="addSkill" title="Add" icon="add" label="Add Entry"/>
                </div>
                </div>
                <mdc-body typo="body2" class="sub-label" v-if="newSkillRating">{{newSkillRating}} {{getRatingText(newSkillRating)}}</mdc-body>
            </div>
            <div v-else>
                <mdc-chip-set>
                <mdc-chip v-for="(skill, index) in profile.skills" :key="index" >
                    {{skill.name}}
                    <span v-if="skill.rating" class="rating">{{skill.rating}}</span>
                </mdc-chip>
                </mdc-chip-set>
            </div>
            </section>
        </mdc-card-text>
    </div>
</template>

<script>
import RoundButton from '../components/RoundButton'
import AutoCompleteOutline from '../components/AutoCompleteOutline.vue'
import { mapState } from 'vuex'
export default {
  name: 'SkillsSection',
  components: {
    draggable: () => import('vuedraggable'),
    RoundButton,
    AutoCompleteOutline
  },
  props: {
    editable: {
      type: Boolean,
      default: false
    },
    profile: Object
  },
  data () {
    return {
      editingSkills: false,
      skills: [],
      newSkillOption: { name: '' },
      newSkill: '',
      newSkillRating: null,
      ratingText: {
        '': '',
        '1★': 'Basics - You have a common knowledge or an understanding of basic techniques and concepts.',
        '2★': 'Novice - You have the level of experience gained in a classroom and/or experimental scenarios or as a trainee on-the-job. You are expected to need help when performing this skill.',
        '3★': 'Intermediate - You are able to successfully complete tasks in this competency as requested. Help from an expert may be required from time to time, but you can usually perform the skill independently.',
        '4★': 'Advanced - You can perform the actions associated with this skill without assistance. You are certainly recognised within your immediate organisation as "a person to ask" when difficult questions arise regarding this skill.',
        '5★': 'Expert - You are known as an expert in this area. You can provide guidance, troubleshoot and answer questions related to this area of expertise and the field where the skill is used.'
      }
    }
  },
  computed: {
    ...mapState({
      skillList: (state) => state.skills
    })
  },
  methods: {
    editProfileSkills () {
      this.editingSkills = true
      if (this.profile.skills) {
        this.skills = this.profile.skills
      }
    },
    setNewSkill (val) {
      if (val) {
        this.newSkill = val.name
      }
    },
    updateProfileSkills () {
      if (this.newSkill && this.newSkillRating) {
        this.addSkill()
      }
      this.$emit('updateProfileSection', { skills: this.skills })
      this.editingSkills = false
    },
    addSkill () {
      const skill = this.newSkill
      if (!this.skills) {
        this.skills = []
      }
      if (skill !== '') {
        this.skills.push({ name: skill, rating: this.newSkillRating })
        this.newSkill = ''
        this.newSkillRating = ''
        this.newSkillOption.name = ''
      }
    },
    removeSkill (index) {
      this.skills.splice(index, 1)
    },
    getRatingText (rating) {
      return this.ratingText[rating]
    }
  }
}
</script>

<style scoped>
.skills .flex-row .flex-left {
  flex:1;
}
.skills .item .handle {
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  align-items: center;
}
.skills .item .handle .handleicon {
  margin-right: 6px;
}
.skills .item .handle .mdc-chip__text {
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 6px;
}
.skills .item .handle .rating {
  margin-right: 22px
}

.skills .item.mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing {
  position:absolute;
  right:12px;
}

.skills .flex-row {
  display:flex;
  flex-flow:row;
}
.skills .flex-row .mdc-button {
  min-width:20px;
}
.skills .rating {
  background-color: var(--mdc-theme-primary, #2D9CDB);
  border-radius: 16px;
  padding: 3px 10px 3px 10px;
  color: white;
}
.skills .mdc-select--box:not(.mdc-select--disabled).dense {
  background-color:white;
  height:auto;
  min-width:40px;
}
.skills .mdc-select--box:not(.mdc-select--disabled).dense select {
  height:auto;
  padding:0;
  padding-left:6px;
  height:36px;
}
.skills .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  margin-right:12px;
}
.skills .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
  height:36px;
}
.skills {
  margin-bottom:16px;
}
</style>
<style>
.skills .autocomplete .autocomplete.mdc-text-field.mdc-text-field--upgraded.outlined[data-v-09396c1a] {
  margin-bottom: 0px;
}
</style>
